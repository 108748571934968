import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { PostLoginService } from './post-login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthFederatedService {
  incorrectUsernameOrPassword: boolean;

  urlBeforeLogin: string;
  redirectTo: string;

  readonly cognitoDomain = environment.cognitoDomain;
  readonly clientId = environment.cognitoClientId;
  readonly callbackUrl = `${window.location.origin}/callback`;

  constructor(private http: HttpClient, private authService: AuthService, private postLoginService: PostLoginService) {
    // sub to the url before that hit guard on before going to log screen
    this.authService.UrlBeforeLogin.subscribe((resp: string) => {
      this.urlBeforeLogin = resp;
    });

    // TO DO: implement OAUTH
    // window.addEventListener('message', this.handleAuthMessage.bind(this), false);
  }

  federatedSignIn(redirect_uri: string, code: string, cognitoDomain: string): Observable<any> {
    const url: string = `${environment.naybrsAwsApi}/user/federated/login`;
    const body: {
      redirect_uri: string;
      code: string;
      cognitoDomain: string;
      magentoOnlyNotCognito: true;
    } = {
      redirect_uri,
      code,
      cognitoDomain,
      magentoOnlyNotCognito: true,
    };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers });
  }

  openAuthWindow(url: string): void {
    const width = 500;
    const height = 600;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    window.open(url, 'authWindow', `width=${width},height=${height},top=${top},left=${left}`);
  }

  // TO DO: implement OAUTH
  // // Handle the OAuth response from the authentication window
  // private handleAuthMessage(event: MessageEvent) {
  //   const trustedDomains = ['naybrs.com', 'google.com', 'facebook.com', 'apple.com'];

  //   const isTrustedOrigin = (origin: string) => {
  //     try {
  //       const url = new URL(origin);
  //       return trustedDomains.some((domain) => url.hostname === domain || url.hostname.endsWith(`.${domain}`));
  //     } catch (e) {
  //       return false;
  //     }
  //   };

  //   if (!isTrustedOrigin(event.origin)) {
  //     // console.warn('Untrusted message origin:', event.origin);
  //     return;
  //   }

  //   const { tokens } = event.data;
  //   if (tokens) {
  //     console.log('Received OAuth tokens', tokens);
  //     // Handle the OAuth code (e.g., send it to your backend or use it in your application)

  //     this.handleTokenExchange(tokens);
  //   } else {
  //     console.log('No code in message', event.data);

  //     let message = 'Could not log you in, please try again.';

  //     setTimeout(() => {
  //       this.incorrectUsernameOrPassword = true;
  //     }, 5000);

  //     console.log(message);
  //   }
  // }

  // TO DO: implement OAUTH
  // handleTokenExchange(tokens: CognitoToken) {
  //   // Set the JWT token (could be handled in AuthService too)
  //   this.authService.setUserData(tokens);

  //   this.postLoginService.handlePostLoginOperations();
  // }
}
