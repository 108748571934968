import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, interval } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { AuthUser } from '../auth/user.model';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.authService.authUser.pipe(
      take(1),
      map((user) => {
        const isAuth = !!user;
        console.log('authGuard', user);

        if (isAuth) {
          return true;
        }

        this.authService.redirectUrl = state.url;
        console.log('redirecturl', this.authService.redirectUrl);
        this.authService.saveUrlBeforeLogin(decodeURIComponent(this.authService.redirectUrl));

        // if the user is verified, then send them to dashboard. If not, then we require their application for a full account.
        if (user) {
          if ((user as unknown as AuthUser).verified && state.url !== '/dashboard') {
            return this.router.createUrlTree(['/dashboard'], {
              queryParamsHandling: 'preserve',
            });
          } else if (!(user as unknown as AuthUser).verified && state.url !== '/application') {
            return this.router.createUrlTree(['/application'], {
              queryParamsHandling: 'preserve',
            });
          }
        }

        return this.router.createUrlTree(['/auth/login']);
      })
    );
  }
}
