import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component'; // adjust the path as needed
import { ApplicationComponent } from './pages/application/application.component'; // adjust the path as needed
import { AuthGuard } from './guards/auth.guard';
import { VerifiedAccountGuard } from './guards/verified-account.guard';
import { OauthCallbackComponent } from './auth/components/oauth-callback/oauth-callback.component';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // automatic root redirect to login
  { path: 'auth', loadChildren: () => import('./auth/auth.routes').then((m) => m.authRoutes) },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, VerifiedAccountGuard] },
  { path: 'application', component: ApplicationComponent, canActivate: [AuthGuard] },
  { path: 'verify', component: OauthCallbackComponent },
];
