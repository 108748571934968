<footer class="footer sec-padding ww-border-t">
  <div class="container">
    <div class="footer-wrapper flex-wrap justify-space-between items-start">
      <div class="footer-widget about-widget flex-100 flex-gt-sm-30 flex-sm-50">
        <a class="logo" href="index.html">
          <naybrs-logo [width]="'170'"> </naybrs-logo>
        </a>
        <p>community commerce.</p>
      </div>
    </div>
  </div>

  <div class="appstores">
    <a href="https://apps.apple.com/us/app/naybrs/id6448700683">
      <img src="../../../../assets/images/appstore/apple-appstore-badge-100.png" alt="Apple Appstore">
    </a>

    <a href="https://play.google.com/store/apps/details?id=com.wrightwaystore.com&hl=en_US&gl=US
    ">
      <img src="../../../../assets/images/appstore/google-play-badge-100.png" alt="Google Play Store">

    </a>
  </div>
  <div class="copyright">
    <div class="child"><small class="copyright-text">Copyright {{ currentYear }}, Naybrs, Inc. All rights
        reserved.</small></div>
  </div>
</footer>