import { Component } from '@angular/core';

import { NaybrsLogoComponent } from '../naybrs-logo/naybrs-logo.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';

import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { filter } from 'rxjs';
import { AuthUser } from '../../auth/user.model';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NaybrsLogoComponent,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButton,
    MatTooltip,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isLoggedIn: boolean = false;
  user: AuthUser | null;
  isLoading: boolean = false;
  currentRoute: string;

  constructor(private router: Router, private authService: AuthService, public dialog: MatDialog) {}

  async ngOnInit() {
    // Subscribe to the router's events observable
    this.router.events
      .pipe(
        // Filter out events that are not NavigationEnd events
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // Update currentRoute with the url of the NavigationEnd event
        this.currentRoute = event.url;
      });

    this.authService.currentAuthUser.subscribe(async (resp: any) => {
      console.log('current user', resp);

      if (resp && Object.keys(resp).length > 0) {
        this.isLoggedIn = true;
        this.user = resp;
      } else return;
    });

    const authData = JSON.parse(localStorage.getItem('authData') || '{}');
    const userData = JSON.parse(localStorage.getItem('userObject') || '{}');

    if (authData && Object.keys(authData).length > 0 && userData && Object.keys(userData).length > 0) {
      this.isLoggedIn = true;
      this.user = userData;
    } else {
      return;
    }
  }

  async logout(): Promise<void> {
    this.authService.setUserData(null);
    localStorage.removeItem('authData');
    this.router.navigate(['/auth/login']);
  }

  navigateToLogin() {
    this.router.navigate(['/account'], {
      queryParams: {
        mode: 'login',
      },
    });
  }

  changeTab(url: string) {
    this.router.navigate(['p/profile/'], {
      //relativeTo: this.route,
      queryParams: {
        section: url,
      },
    });
  }
}
