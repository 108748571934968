import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LogoComponent } from '../logo/logo.component';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { DeviceBreakpoints } from '../../models/misc.model';

@Component({
  selector: 'app-full-page-logo',
  standalone: true,
  imports: [MatProgressSpinnerModule, LogoComponent],
  templateUrl: './full-page-logo.component.html',
  styleUrl: './full-page-logo.component.scss',
})
export class FullPageLogoComponent {
  devices: DeviceBreakpoints;
  constructor(private breakpointService: BreakpointService) {
    this.breakpointService.devices$.subscribe((devices) => (this.devices = devices));
  }
}
