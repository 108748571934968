import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormsModule } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointService } from '../services/breakpoint.service';
import { DeviceBreakpoints } from 'src/app/models/misc.model';

export interface Proof {
  code: string;
  title: string;
  description: string;
}

interface MyFileDropEntry extends NgxFileDropEntry {
  base64String?: string;
}

@Component({
  selector: 'app-file-drop',
  standalone: true,
  imports: [
    NgxFileDropModule,
    FormsModule,
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
  ],
  templateUrl: './file-drop.component.html',
  styleUrl: './file-drop.component.scss',
})
export class FileDropComponent {
  @Input() proofCode: string;
  @Input() existingImage: string;
  @Output() base64Upload: EventEmitter<any> = new EventEmitter<any>();

  public files: MyFileDropEntry[] = [];

  mobileFile: string | null;
  mobileFileName: string | null;

  devices: DeviceBreakpoints;

  userWarning: { visible: boolean; message: string } = {
    visible: false,
    message: 'Invalid file type. Please upload a PDF, PNG, JPG, or WebP file',
  };

  verificationDocuments: Proof[] = [
    {
      code: 'proof-of-address',
      title: 'Proof of Address',
      description:
        'Upload a recent utility bill, lease agreement, or official government correspondence that clearly shows your name and residential address. The document must be dated within the last 30 days.',
    },
    {
      code: 'proof-of-bank',
      title: 'Proof of Bank Account',
      description:
        "Please provide a bank statement or official letter from your bank confirming your account details. The document should include your name, bank's name, and account number. It must be dated within the last 90 days.",
    },
    {
      code: 'proof-of-identity',
      title: 'Proof of Identity',
      description:
        "Upload a clear, high-resolution scan or photo of your government-issued identification such as a passport or driver's license. Ensure your name, photo, and ID number are clearly visible. The ID must be valid and not expired.",
    },
  ];

  constructor(private breakpointService: BreakpointService) {
    this.breakpointService.devices$.subscribe((devices) => (this.devices = devices));
  }

  ngOnInit() {
    // displays already uploaded image. converts to base64 first
    if (this.existingImage) {
      fetch(this.existingImage)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.mobileFile = event.target.result;
            if (!this.files || !Array.isArray(this.files)) {
              this.files = [];
            }
            if (!this.files[0]) {
              this.files[0] = {} as MyFileDropEntry;
            }
            this.files[0].base64String = event.target.result;
          };
          reader.readAsDataURL(blob);
        });
    }
  }

  getDocument(code: string) {
    return this.verificationDocuments.find((document) => document.code === code);
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      this.userWarning = {
        visible: true,
        message: 'Only one file can be uploaded at a time.',
      };
      setTimeout(() => {
        this.userWarning = {
          visible: false,
          message: 'Only one file can be uploaded at a time.',
        };
      }, 5000);
      return;
    }

    const processedFiles: NgxFileDropEntry[] = []; // Create a new array to store the processed files
    const promises: Promise<void>[] = []; // Create an array to store the promises

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const promise = new Promise<void>((resolve, reject) => {
          fileEntry.file((file: File) => {
            // Here you can access the real file
            console.log(droppedFile.relativePath, file);

            // Check the file type
            const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
            if (!validFileTypes.includes(file.type)) {
              this.userWarning = {
                visible: true,
                message: 'Invalid file type. Please upload a PDF, PNG, JPG, or WebP file.',
              };
              setTimeout(() => {
                this.userWarning = {
                  visible: false,
                  message: 'Invalid file type. Please upload a PDF, PNG, JPG, or WebP file.',
                };
              }, 5000);
              resolve();
              return;
            }

            // The file type is valid, convert it to a base64 string
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result as string;
              // console.log(base64String); // Log the base64 string to the console
              // Add the base64 string to the droppedFile object
              (droppedFile as any).base64String = base64String;
              (droppedFile as any).proofCode = this.proofCode;

              console.log(droppedFile, 'droppedFile');

              // Add the modified droppedFile object to the processedFiles array
              processedFiles.push(droppedFile);
              resolve();
            };
            reader.readAsDataURL(file);
          });
        });
        promises.push(promise);
      }
    }

    // Wait for all promises to resolve, then assign the processedFiles array to this.files
    Promise.all(promises).then(() => {
      this.files = processedFiles;

      // emit the processed files back to parent element
      this.base64Upload.emit(processedFiles[0]);
    });
  }

  onNativeFileSelected(event: any, proofCode: string) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader?.result?.toString();
      // Now you can use the base64String
      console.log(base64String);

      if (base64String) {
        this.mobileFile = base64String;
        this.mobileFileName = file.name;
        this.base64Upload.emit([{ base64String: base64String, fileName: file.name }]);
      }
      reader.onerror = (error) => {
        console.error('Error: ', error);
      };
    };
  }

  clearMobileUploadedFile() {
    this.mobileFile = null;
    this.mobileFileName = null;
    this.base64Upload.emit(null);
  }

  clearUploadedFile() {
    this.files = [];
    // emit the processed files back to parent element
    this.base64Upload.emit(this.files);
  }

  public fileOver(event: any) {
    console.log(event);
  }

  public fileLeave(event: any) {
    console.log(event);
  }
}
