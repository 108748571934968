import { Component } from '@angular/core';
import { NaybrsLogoComponent } from '../naybrs-logo/naybrs-logo.component'

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NaybrsLogoComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  year: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
  }
  currentYear = new Date().getFullYear()

}
