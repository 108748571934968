import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service'; // replace with your service
import { AuthUser } from '../auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class VerifiedAccountGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.authUser.pipe(
      filter((user) => user !== null), // Ignore null values
      map((resp: any) => {
        if ('verified' in resp && resp.verified) {
          return true;
        } else {
          // redirect to application page if not verified
          return this.router.parseUrl('/application');
        }
      })
    );
  }
}
