import { AsyncPipe, CommonModule, KeyValuePipe, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { Observable, Subject, Subscription, firstValueFrom, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import {
  AccountApplication,
  BankDetails,
  ContactInformation,
  GovernmentIssuedID,
  ProofOfAddress,
  ResidentialAddress,
} from '../../models/application.model';
import {
  ALLOWED_PROOF_OF_IDENTIFICATION,
  COUNTRIES,
  ESTIMATED_MONTHLY_REVENUE,
  US_STATES,
} from '../../shared/constants';
import { FileDropComponent } from '../../shared/file-drop/file-drop.component';
import { AuthService } from '../../shared/services/auth.service';
import { DataService } from '../../shared/services/data.service';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { DeviceBreakpoints } from 'src/app/models/misc.model';
import { BreakpointService } from '../../shared/services/breakpoint.service';

interface Field {
  fieldName: string;
  title: string;
  value: string;
  inputType: string;
  options?: Option[];
}

interface Form {
  formTitle: string;
  displayOrder: number;
  fields: Field[];
}

interface Option {
  code: string;
  name: string;
}

export interface FormsPresentationData {
  basicInfo: Form;
  residentialAddress: Form;
  identification: Form;
  financialInformation: Form;
  expectedActivity: Form;
}

@Component({
  selector: 'app-application',
  standalone: true,
  imports: [
    KeyValuePipe,
    AsyncPipe,
    NgForOf,
    NgIf,
    FormsModule,
    MatCheckboxModule,
    MatCardModule,
    CommonModule,
    ReactiveFormsModule,
    FileDropComponent,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    MatDatepickerModule,
  ],
  templateUrl: './application.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './application.component.scss',
})
export class ApplicationComponent {
  @ViewChild('basicInfo') basicInfo: ElementRef;
  @ViewChild('residentialAddress') residentialAddress: ElementRef;
  @ViewChild('verification') verification: ElementRef;
  @ViewChild('financialInfo') financialInfo: ElementRef;
  @ViewChild('expectedActivity') expectedActivity: ElementRef;
  @ViewChild('fileInput') fileInput: any;

  confirmSubmitApplicationMode: boolean = false;

  basicInfoForm: FormGroup;
  verificationForm: FormGroup;
  financialInfoForm: FormGroup;
  residentialAddressForm: FormGroup;
  expectedActivityForm: FormGroup;

  countries = COUNTRIES;
  usStates = US_STATES;
  allowedProofOfIdentification = ALLOWED_PROOF_OF_IDENTIFICATION;
  estimatedMonthlyRevenue = ESTIMATED_MONTHLY_REVENUE;

  sections: string[] = [
    'basicInfo',
    'residentialAddress',
    'identification',
    'financialInformation',
    'expectedActivity',
  ];
  currentSectionIndex: number = 0;

  userAuthDetails: any;
  minDate = new Date();

  private saveRequests = new Subject<void>();
  private saveSubscription: Subscription;

  isHandset$: Observable<boolean>;
  isTablet$: Observable<boolean>;
  isWeb$: Observable<boolean>;

  notFirstVisit: boolean = true;

  reviewApplicationMode: boolean = false;

  termsAccepted: boolean = true;

  savedData: AccountApplication;

  submitApplicationError: string;

  devices: DeviceBreakpoints;

  pageSections = [
    {
      title: 'Welcome Back',
      matIcon: '',
      subtitle: "Let's pick up where we left off, starting with:",
      information: '',
      code: 'welcome-back',
    },
    {
      title: 'Basic Information',
      matIcon: 'person',
      subtitle: '',
      information: '',
      code: 'basic-information',
    },
    {
      title: 'Residential Address',
      matIcon: 'home',
      subtitle: 'Enter your current place of residence.',
      information: '',
      code: 'residential-address',
    },
    {
      title: 'Identification',
      matIcon: 'badge',
      subtitle: 'Confirm your identity',
      information: '',
      code: 'identification',
    },
    {
      title: 'Banking Details',
      matIcon: 'assured_workload',
      subtitle: 'Link your bank account for secure transactions and fund transfers.',
      information: '',
      code: 'banking-details',
    },
    {
      title: 'Additional Information',
      matIcon: 'data_usage',
      subtitle: '',
      information: '',
      code: 'additional-information',
    },
  ];
  formsPresentationData: FormsPresentationData;

  getPageSection(sectionCode: string) {
    return this.pageSections.find((item) => item.code === sectionCode);
  }

  toggleReviewApplicationMode() {
    // toggle reviewApplicationMode: boolean
    this.reviewApplicationMode = !this.reviewApplicationMode;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private breakpointService: BreakpointService
  ) {
    this.authService.authUser.subscribe((resp: any) => {
      this.userAuthDetails = resp;

      console.log('userAuthDetails', this.userAuthDetails);

      if (resp?.user) {
        this.initializeForms();
      }
    });
    this.minDate.setFullYear(this.minDate.getFullYear() - 13);

    this.breakpointService.devices$.subscribe((devices) => (this.devices = devices));
  }

  ngOnInit() {
    this.saveSubscription = this.saveRequests
      .pipe(
        debounceTime(1000) // Adjust this value as needed
      )
      .subscribe(() => {
        this.saveFormDataToServer();
      });
  }

  ngOnDestroy() {
    this.saveSubscription.unsubscribe();
  }

  // // makes a popup when the user goes to close the window and fires the save data HTTP request
  // @HostListener('window:beforeunload', ['$event'])
  // onWindowClose(event: any): void {
  //   // Prevent the window from closing
  //   event.preventDefault();
  //   event.returnValue = true;

  //   // Trigger the save function
  //   this.triggerSaveFormData();
  // }

  async goToNextSection(goToSectionCode: string = '') {
    if (this.currentSectionIndex < this.sections.length - 1) {
      this.currentSectionIndex++;

      this.triggerSaveFormData();

      const isHandset = await firstValueFrom(this.isHandset$);
      const isTablet = await firstValueFrom(this.isTablet$);

      if (isHandset || isTablet) {
        console.log('handsetTablet', isHandset, isTablet);

        const nextSectionCode = this.getPageSection(goToSectionCode)?.code;
        this.scrollToSection(nextSectionCode);
      }
    }
  }

  goToPreviousSection() {
    if (this.currentSectionIndex > 0) {
      this.currentSectionIndex--;

      this.triggerSaveFormData();
    }
  }

  initializeForms() {
    this.expectedActivityForm = this.formBuilder.group({
      estimatedMonthlyRevenue: ['BETWEEN_5000_AND_10000', [Validators.required, Validators.min(0)]],
      intendedUseOfAccount: ['To manage my business transactions', [Validators.required, Validators.minLength(10)]],
    });

    this.basicInfoForm = this.formBuilder.group({
      firstName: [this.userAuthDetails?.user?.first_name || '', [Validators.required, Validators.minLength(2)]],
      familyName: [this.userAuthDetails?.user?.family_name || '', [Validators.required, Validators.minLength(2)]],
      dob: [
        this.userAuthDetails?.user?.date_of_birth || '',
        [Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/)],
      ],
      phone: [
        this.userAuthDetails.user.contact_information?.phone_number || '',
        [Validators.required, Validators.pattern('^[2-9]\\d{2}[2-9](?!11)\\d{6}$')],
      ], // US phone number validation
      socialSecurityNumber: [
        this.userAuthDetails.user?.social_security_number || '',
        [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{2}-[0-9]{4}$')],
      ], // US SSN validation
    });

    this.residentialAddressForm = this.formBuilder.group({
      street: [
        this.userAuthDetails.user?.residential_address?.street || '',
        [Validators.required, Validators.minLength(3)],
      ],
      city: [
        this.userAuthDetails.user?.residential_address?.city || '',
        [Validators.required, Validators.minLength(3)],
      ],
      state: [
        this.userAuthDetails.user?.residential_address?.state_or_province || '',
        [Validators.required, Validators.minLength(2)],
      ],
      zip: [
        this.userAuthDetails.user?.residential_address?.zip || '',
        [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')],
      ], // US ZIP code validation
      country: [
        this.userAuthDetails.user?.residential_address?.country || '',
        [Validators.required, Validators.minLength(2)],
      ],
      proofOfAddress: [this.userAuthDetails.user?.proof_of_address?.proof_url || '', [Validators.required]],
    });

    this.verificationForm = this.formBuilder.group({
      idType: [
        this.userAuthDetails.user?.government_issued_id?.id_type || '',
        [Validators.required, Validators.minLength(3)],
      ],
      idNumber: [
        this.userAuthDetails.user?.government_issued_id?.id_number || '',
        [Validators.required, Validators.minLength(3)],
      ],
      idExpiry: [this.userAuthDetails.user?.government_issued_id?.expiry_date || '', [Validators.required]],
      idCountry: [
        this.userAuthDetails.user?.government_issued_id?.issuing_country || '',
        [Validators.required, Validators.minLength(2)],
      ],
      proofOfId: [this.userAuthDetails.user?.government_issued_id?.proof_url || '', [Validators.required]],
    });

    this.financialInfoForm = this.formBuilder.group({
      bankName: [
        this.userAuthDetails.user?.bank_details?.bank_name || '',
        [Validators.required, Validators.minLength(3)],
      ],
      accountNumber: [
        this.userAuthDetails.user?.bank_details?.account_number || '',
        [Validators.required, Validators.minLength(5)],
      ],
      routingNumber: [
        this.userAuthDetails.user?.bank_details?.routing_number || '',
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ], // US routing number validation
      proofOfBank: [this.userAuthDetails.user?.bank_details?.proof_url || '', [Validators.required]],
    });

    this.expectedActivityForm = this.formBuilder.group({
      estimatedMonthlyRevenue: [
        this.userAuthDetails.user?.estimated_monthly_revenue || '',
        [Validators.required, Validators.min(0)],
      ],
      intendedUseOfAccount: [
        this.userAuthDetails.user?.intended_use_account || '',
        [Validators.required, Validators.minLength(10)],
      ],
    });

    this.formsPresentationData = {
      basicInfo: {
        formTitle: 'Basic Information',
        displayOrder: 0,
        fields: [
          {
            fieldName: 'firstName',
            title: 'First Name',
            value: this.basicInfoForm?.get('firstName')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'familyName',
            title: 'Family Name',
            value: this.basicInfoForm?.get('familyName')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'dob',
            title: 'Date of Birth',
            value: this.basicInfoForm?.get('dob')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'phone',
            title: 'Phone Number',
            value: this.basicInfoForm?.get('phone')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'socialSecurityNumber',
            title: 'Social Security Number',
            value: this.basicInfoForm?.get('socialSecurityNumber')?.value || '',
            inputType: 'text',
          },
        ],
      },
      residentialAddress: {
        formTitle: 'Residential Address',
        displayOrder: 1,
        fields: [
          {
            fieldName: 'street',
            title: 'Street',
            value: this.residentialAddressForm?.get('street')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'city',
            title: 'City',
            value: this.residentialAddressForm?.get('city')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'state',
            title: 'State',
            value: this.residentialAddressForm?.get('state')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'zip',
            title: 'Postal Code',
            value: this.residentialAddressForm?.get('zip')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'country',
            title: 'Country',
            value: this.residentialAddressForm?.get('country')?.value || '',
            inputType: 'select',
            options: this.countries,
          },
          {
            fieldName: 'proofOfAddress',
            title: 'Proof of Address',
            value: this.residentialAddressForm?.get('proofOfAddress')?.value || '',
            inputType: 'image',
          },
        ],
      },
      identification: {
        formTitle: 'Identification',
        displayOrder: 2,
        fields: [
          {
            fieldName: 'idType',
            title: 'ID Type',
            value: this.verificationForm?.get('idType')?.value || '',
            inputType: 'select',
            options: this.allowedProofOfIdentification,
          },
          {
            fieldName: 'idNumber',
            title: 'ID Number',
            value: this.verificationForm?.get('idNumber')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'idExpiry',
            title: 'Expiration Date',
            value: this.verificationForm?.get('idExpiry')?.value || '',
            inputType: 'date',
          },
          {
            fieldName: 'idCountry',
            title: 'Issuing Country',
            value: this.verificationForm?.get('idCountry')?.value || '',
            inputType: 'select',
            options: this.countries,
          },
          {
            fieldName: 'proofOfId',
            title: 'Proof of Identity',
            value: this.verificationForm?.get('proofOfId')?.value || '',
            inputType: 'image',
          },
        ],
      },
      financialInformation: {
        formTitle: 'Financial Information',
        displayOrder: 3,
        fields: [
          {
            fieldName: 'bankName',
            title: 'Bank Name',
            value: this.financialInfoForm?.get('bankName')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'accountNumber',
            title: 'Account Number',
            value: this.financialInfoForm?.get('accountNumber')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'routingNumber',
            title: 'Routing Number',
            value: this.financialInfoForm?.get('routingNumber')?.value || '',
            inputType: 'text',
          },
          {
            fieldName: 'proofOfBank',
            title: 'Proof of Bank Account',
            value: this.financialInfoForm?.get('proofOfBank')?.value || '',
            inputType: 'image',
          },
        ],
      },
      expectedActivity: {
        formTitle: 'Expected Activity',
        displayOrder: 4,
        fields: [
          {
            fieldName: 'estimatedMonthlyRevenue',
            title: 'Est. Monthly Revenue',
            value: this.expectedActivityForm?.get('estimatedMonthlyRevenue')?.value || '',
            inputType: 'select',
            options: this.estimatedMonthlyRevenue,
          },
          {
            fieldName: 'intendedUseOfAccount',
            title: 'Intended use',
            value: this.expectedActivityForm?.get('intendedUseOfAccount')?.value || '',
            inputType: 'textarea',
          },
        ],
      },
    };
  }

  getPrettyFormValue(formTitle: string, fieldTitle: string, formValue: string) {
    switch (formTitle) {
      case 'Identification':
        if (fieldTitle === 'ID Type') {
          const idType = this.allowedProofOfIdentification.find((id) => id.code === formValue);
          return idType ? idType.name : formValue;
        }
        return formValue;

      case 'Expected Activity':
        if (fieldTitle === 'Est. Monthly Revenue') {
          const revenue = this.estimatedMonthlyRevenue.find((rev) => rev.code === formValue);
          return revenue ? revenue.name : formValue;
        }
        return formValue;

      default:
        return formValue;
    }
  }

  printInvalidParts() {
    const forms = [
      this.basicInfoForm,
      this.residentialAddressForm,
      this.verificationForm,
      this.financialInfoForm,
      this.expectedActivityForm,
    ];
    let invalidControls: any = [];

    forms.forEach((form) => {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control?.invalid) {
          console.log(`Invalid control in form: ${key}`);
          invalidControls.push(key);
        }
      });
    });

    return invalidControls;
  }

  triggerSaveFormData() {
    this.saveRequests.next();
  }

  showIdNumberOrNot(idType: string) {
    const idTypeInfo = this.allowedProofOfIdentification.find((id) => id.code === idType);
    return idTypeInfo ? idTypeInfo.hasIdNumber : false;
  }

  showExpiryDateOrNot(idType: string) {
    const idTypeInfo = this.allowedProofOfIdentification.find((id) => id.code === idType);
    return idTypeInfo ? idTypeInfo.hasExpirationDate : false;
  }

  prepareServerSaveObject(): AccountApplication {
    const accountApplication: AccountApplication = {
      account_type: 'owner',
      first_name: '',
      family_name: '',
      date_of_birth: '',
      social_security_number: 0,
      residential_address: {} as ResidentialAddress,
      contact_information: {} as ContactInformation,
      government_issued_id: {} as GovernmentIssuedID,
      proof_of_address: {} as ProofOfAddress,
      bank_details: {} as BankDetails,
      estimated_monthly_revenue: '',
      nature_of_transactions: '',
      intended_use_account: '',
    };

    // We only add the items to the prepare for server object if theyre different on the form than the ones that were originally pulled from the server.

    if (
      this.basicInfoForm.value.firstName &&
      this.basicInfoForm.value.firstName !== this.userAuthDetails?.user?.first_name
    ) {
      accountApplication.first_name = this.basicInfoForm.value.firstName;
    }
    if (
      this.basicInfoForm.value.familyName &&
      this.basicInfoForm.value.familyName !== this.userAuthDetails?.user?.family_name
    ) {
      accountApplication.family_name = this.basicInfoForm.value.familyName;
    }
    if (this.basicInfoForm.value.dob && this.basicInfoForm.value.dob !== this.userAuthDetails?.user?.date_of_birth) {
      accountApplication.date_of_birth = this.basicInfoForm.value.dob;
    }
    if (
      this.basicInfoForm.value.phone &&
      this.basicInfoForm.value.phone !== this.userAuthDetails?.user?.contact_information?.phone_number
    ) {
      if (accountApplication.contact_information) {
        accountApplication.contact_information.phone_number = this.basicInfoForm.value.phone;
      }
    }
    if (
      this.basicInfoForm.value.socialSecurityNumber &&
      this.basicInfoForm.value.socialSecurityNumber !== this.userAuthDetails.user?.social_security_number
    ) {
      accountApplication.social_security_number = this.basicInfoForm.value.socialSecurityNumber;
    }

    if (
      this.residentialAddressForm.value.street &&
      this.residentialAddressForm.value.street !== this.userAuthDetails.user?.residential_address?.street
    ) {
      if (accountApplication.residential_address) {
        accountApplication.residential_address.street = this.residentialAddressForm.value.street;
      }
    }
    if (
      this.residentialAddressForm.value.city &&
      this.residentialAddressForm.value.city !== this.userAuthDetails.user?.residential_address?.city
    ) {
      if (accountApplication.residential_address) {
        accountApplication.residential_address.city = this.residentialAddressForm.value.city;
      }
    }
    if (
      this.residentialAddressForm.value.state &&
      this.residentialAddressForm.value.state !== this.userAuthDetails.user?.residential_address?.state_or_province
    ) {
      if (accountApplication.residential_address) {
        accountApplication.residential_address.state_or_province = this.residentialAddressForm.value.state;
      }
    }
    if (
      this.residentialAddressForm.value.zip &&
      this.residentialAddressForm.value.zip !== this.userAuthDetails.user?.residential_address?.zip
    ) {
      if (accountApplication.residential_address) {
        accountApplication.residential_address.zip = this.residentialAddressForm.value.zip;
      }
    }
    if (
      this.residentialAddressForm.value.country &&
      this.residentialAddressForm.value.country !== this.userAuthDetails.user?.residential_address?.country
    ) {
      if (accountApplication.residential_address) {
        accountApplication.residential_address.country = this.residentialAddressForm.value.country;
      }
    }
    if (
      this.residentialAddressForm.value.proofOfAddress &&
      this.residentialAddressForm.value.proofOfAddress !== this.userAuthDetails.user?.proof_of_address?.proof_url
    ) {
      if (accountApplication.proof_of_address) {
        accountApplication.proof_of_address.proof_base64 = this.residentialAddressForm.value.proofOfAddress;
      }
    }

    if (
      this.verificationForm.value.idType &&
      this.verificationForm.value.idType !== this.userAuthDetails.user?.government_issued_id?.id_type
    ) {
      if (accountApplication.government_issued_id) {
        accountApplication.government_issued_id.id_type = this.verificationForm.value.idType;
      }
    }
    if (
      this.verificationForm.value.idNumber &&
      this.verificationForm.value.idNumber !== this.userAuthDetails.user?.government_issued_id?.id_number
    ) {
      if (accountApplication.government_issued_id) {
        accountApplication.government_issued_id.id_number = this.verificationForm.value.idNumber;
      }
    }
    if (
      this.verificationForm.value.idExpiry &&
      this.verificationForm.value.idExpiry !== this.userAuthDetails.user?.government_issued_id?.expiry_date
    ) {
      if (accountApplication.government_issued_id) {
        accountApplication.government_issued_id.expiry_date = this.verificationForm.value.idExpiry;
      }
    }
    if (
      this.verificationForm.value.idCountry &&
      this.verificationForm.value.idCountry !== this.userAuthDetails.user?.government_issued_id?.issuing_country
    ) {
      if (accountApplication.government_issued_id) {
        accountApplication.government_issued_id.issuing_country = this.verificationForm.value.idCountry;
      }
    }
    if (
      this.verificationForm.value.proofOfId &&
      this.verificationForm.value.proofOfId !== this.userAuthDetails.user?.government_issued_id?.proof_url
    ) {
      if (accountApplication.government_issued_id) {
        accountApplication.government_issued_id.proof_base64 = this.verificationForm.value.proofOfId;
      }
    }

    if (
      this.financialInfoForm.value.bankName &&
      this.financialInfoForm.value.bankName !== this.userAuthDetails.user?.bank_details?.bank_name
    ) {
      if (accountApplication.bank_details) {
        accountApplication.bank_details.bank_name = this.financialInfoForm.value.bankName;
      }
    }
    if (
      this.financialInfoForm.value.accountNumber &&
      this.financialInfoForm.value.accountNumber !== this.userAuthDetails.user?.bank_details?.account_number
    ) {
      if (accountApplication.bank_details) {
        accountApplication.bank_details.account_number = this.financialInfoForm.value.accountNumber;
      }
    }
    if (
      this.financialInfoForm.value.routingNumber &&
      this.financialInfoForm.value.routingNumber !== this.userAuthDetails.user?.bank_details?.routing_number
    ) {
      if (accountApplication.bank_details) {
        accountApplication.bank_details.routing_number = this.financialInfoForm.value.routingNumber;
      }
    }
    if (
      this.financialInfoForm.value.proofOfBank &&
      this.financialInfoForm.value.proofOfBank !== this.userAuthDetails.user?.bank_details?.proof_url
    ) {
      if (accountApplication.bank_details) {
        accountApplication.bank_details.proof_base64 = this.financialInfoForm.value.proofOfBank;
      }
    }

    if (
      this.expectedActivityForm.value.estimatedMonthlyRevenue &&
      this.expectedActivityForm.value.estimatedMonthlyRevenue !== this.userAuthDetails.user?.estimated_monthly_revenue
    ) {
      accountApplication.estimated_monthly_revenue = this.expectedActivityForm.value.estimatedMonthlyRevenue;
    }
    if (
      this.expectedActivityForm.value.intendedUseOfAccount &&
      this.expectedActivityForm.value.intendedUseOfAccount !== this.userAuthDetails.user?.intended_use_account
    ) {
      accountApplication.intended_use_account = this.expectedActivityForm.value.intendedUseOfAccount;
    }

    return this.removeEmptyKeys(accountApplication);
  }

  removeEmptyKeys(obj: any) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    Object.keys(obj).forEach((key) => {
      if (key === 'social_security_number' && obj[key] === 0) {
        delete obj[key];
      } else if (obj[key] && typeof obj[key] === 'object') {
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        } else {
          this.removeEmptyKeys(obj[key]);
        }
      } else if (obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }

  saveFormDataToServer() {
    const toSave = this.prepareServerSaveObject();

    console.log('data to save', toSave);

    if (Object.keys(toSave).length > 1 && JSON.stringify(toSave) !== JSON.stringify(this.savedData)) {
      console.log('saving data');

      this.dataService.saveApplicationFields(toSave).subscribe((resp) => {
        console.log('saveFields resp', resp);
        this.savedData = toSave;
      });
    } else {
      console.log('no changes in data, not saving');
    }
  }

  handleProofUpload(event: any) {
    const uploadBase64String = event.base64String;

    console.log('event from fileUpload', event);
    this.setProofImageFormValues(event.proofCode, uploadBase64String);
  }

  setProofImageFormValues(proofCode: string, base64String: string) {
    console.log('set form values', proofCode, base64String);
    switch (proofCode) {
      case 'proof-of-address':
        this.residentialAddressForm.patchValue({ proofOfAddress: base64String });
        break;
      case 'proof-of-bank':
        this.financialInfoForm.patchValue({ proofOfBank: base64String });
        break;
      case 'proof-of-identity':
        this.verificationForm.patchValue({ proofOfId: base64String });
        break;
    }
  }

  scrollToSection(sectionCode: string = '') {
    switch (sectionCode) {
      case 'welcome-back':
        // Execute code for "Welcome Back" section
        break;
      case 'basic-information':
        this.basicInfo.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'residential-address':
        this.residentialAddress.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'identification':
        this.verification.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'banking-details':
        this.financialInfo.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'additional-information':
        this.expectedActivity.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        console.log('Invalid section code');
    }
  }

  getFirstInvalidForm(): { title: string; subtitle: string; information: string; code: string } | null {
    for (const section of this.pageSections) {
      const formIsValid = this.checkFormValidity(section.code);
      if (!formIsValid) {
        return section;
      }
    }
    return null; // All forms are valid
  }

  getInvalidForms(): Array<{ title: string; subtitle: string; information: string; code: string; matIcon: string }> {
    const invalidForms = [];
    for (const section of this.pageSections) {
      const formIsValid = this.checkFormValidity(section.code);
      if (!formIsValid) {
        invalidForms.push(section);
      }
    }
    return invalidForms;
  }

  private checkFormValidity(code: string): boolean {
    switch (code) {
      case 'basic-information':
        return this.basicInfoForm.valid;
      case 'residential-address':
        return this.residentialAddressForm.valid;
      case 'identification':
        return this.verificationForm.valid;
      case 'banking-details':
        return this.financialInfoForm.valid;
      case 'additional-information':
        return this.expectedActivityForm.valid;
      default:
        return true;
    }
  }

  submitAccountForReview() {
    this.dataService
      .saveApplicationFields({ account_type: 'owner', under_review: true })
      .pipe(
        catchError((error) => {
          console.error('Error:', error);
          this.submitApplicationError = 'There was an error submitting your application. Please try again.';
          setTimeout(() => {
            this.submitApplicationError = '';
          }, 5000);
          return of(false);
        })
      )
      .subscribe((resp: boolean) => {
        if (resp) {
          this.userAuthDetails.user.under_review = true;
        }
      });
  }
}
