import { Component, OnInit, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'naybrs-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [NgStyle, RouterModule],
})
export class LogoComponent implements OnInit {
  @Input('width') width: string;
  // @Input('height') height: number;

  constructor() {}

  ngOnInit(): void {}
}
