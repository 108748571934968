<div class="flex h-auto content-area justify-center sm:items-center pt-8 sm:pt-0 mb-8 px-4 sm:px-0"
    style="min-height: 100vh;" *ngIf="userAuthDetails.user && userAuthDetails.user.under_review">
    <mat-card class="under-review-card">
        <mat-card-header>
            <mat-card-title>
                <h4 class="text-blue-500 mb-4">Application Under Review</h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p class="mat-mdc-card-subtitle text-gray-500"> Thank you for submitting your application. It is
                currently
                under review. Once the review is complete, we will notify you via email.</p>
        </mat-card-content>
    </mat-card>
</div>

<div class="flex h-auto content-area" *ngIf="userAuthDetails.user && !userAuthDetails.user.under_review"
    [class.bg-gradient]="!reviewApplicationMode && ((devices.isMobile) || (devices.isTablet)) && notFirstVisit && getInvalidForms().length > 0"
    style="padding: 5%;">
    <!-- Column 1 -->
    <div class="flex-grow" style="flex-basis: 18.56%;" *ngIf="(devices.isTablet) || (devices.isDesktop)">
        <mat-nav-list>
            <ng-container *ngIf="!reviewApplicationMode">
                <h3 mat-list-item class="text-center text-gray-600">{{currentSectionIndex + 1}}/{{sections.length}}
                </h3>
                <button mat-list-item [class.selected]="currentSectionIndex === sections.indexOf('basicInfo')"
                    class="section-select-button"
                    (click)="currentSectionIndex = sections.indexOf('basicInfo'); triggerSaveFormData()"
                    class="text-left">
                    <mat-icon matListItemIcon
                        *ngIf="basicInfoForm.pristine || basicInfoForm.valid">{{getPageSection('basic-information')?.matIcon}}</mat-icon>
                    <mat-icon matListItemIcon class="red-icon"
                        *ngIf="basicInfoForm.dirty && basicInfoForm.invalid">warning</mat-icon>
                    <span class="text-gray-600">Basic Information</span>
                    <span matListItemMeta><mat-icon color="primary" *ngIf="basicInfoForm.valid">check</mat-icon>
                    </span>
                </button>
                <button mat-list-item [class.selected]="currentSectionIndex === sections.indexOf('residentialAddress')"
                    class="section-select-button"
                    (click)="currentSectionIndex = sections.indexOf('residentialAddress'); triggerSaveFormData()"
                    class="text-left">
                    <mat-icon matListItemIcon
                        *ngIf="(residentialAddressForm.pristine && residentialAddressForm.valid) || residentialAddressForm.get('proofOfAddress')?.valid">
                        {{getPageSection('residential-address')?.matIcon}}
                    </mat-icon>
                    <mat-icon matListItemIcon class="red-icon"
                        *ngIf="(residentialAddressForm.dirty && residentialAddressForm.invalid) || residentialAddressForm.get('proofOfAddress')?.invalid">warning</mat-icon>

                    <span class="text-gray-600">Residential Address</span>
                    <span matListItemMeta><mat-icon color="primary"
                            *ngIf="residentialAddressForm.valid">check</mat-icon>
                    </span>
                </button>
                <button mat-list-item [class.selected]="currentSectionIndex === sections.indexOf('identification')"
                    class="section-select-button"
                    (click)="currentSectionIndex = sections.indexOf('identification'); triggerSaveFormData()"
                    class="text-left">
                    <mat-icon matListItemIcon
                        *ngIf="(verificationForm.pristine && verificationForm.valid) || verificationForm.get('proofOfId')?.valid">
                        {{getPageSection('identification')?.matIcon}}
                    </mat-icon>
                    <mat-icon matListItemIcon class="red-icon"
                        *ngIf="(verificationForm.dirty && verificationForm.invalid) || verificationForm.get('proofOfId')?.invalid">warning</mat-icon>
                    <span class="text-gray-600">Identification</span>
                    <span matListItemMeta><mat-icon color="primary" *ngIf="verificationForm.valid">check</mat-icon>
                    </span>
                </button>
                <button mat-list-item
                    [class.selected]="currentSectionIndex === sections.indexOf('financialInformation')"
                    class="section-select-button"
                    (click)="currentSectionIndex = sections.indexOf('financialInformation'); triggerSaveFormData()"
                    class="text-left">
                    <mat-icon matListItemIcon
                        *ngIf="(financialInfoForm.pristine && financialInfoForm.valid) || financialInfoForm.get('proofOfBank')?.valid">
                        {{getPageSection('banking-details')?.matIcon}}
                    </mat-icon>
                    <mat-icon matListItemIcon class="red-icon"
                        *ngIf="(financialInfoForm.dirty && financialInfoForm.invalid) || financialInfoForm.get('proofOfBank')?.invalid">warning</mat-icon>
                    <span class="text-gray-600">Banking Details</span>
                    <span matListItemMeta><mat-icon color="primary" *ngIf="financialInfoForm.valid">check</mat-icon>
                    </span>
                </button>
                <button mat-list-item [class.selected]="currentSectionIndex === sections.indexOf('expectedActivity')"
                    class="section-select-button"
                    (click)="currentSectionIndex = sections.indexOf('expectedActivity'); triggerSaveFormData()"
                    class="text-left">
                    <mat-icon matListItemIcon
                        *ngIf="expectedActivityForm.pristine || expectedActivityForm.valid">{{getPageSection('additional-information')?.matIcon}}</mat-icon>
                    <mat-icon matListItemIcon class="red-icon"
                        *ngIf="expectedActivityForm.dirty && expectedActivityForm.invalid">warning</mat-icon>

                    <span class="text-gray-600">Additional Information</span>
                    <span matListItemMeta><mat-icon color="primary" *ngIf="expectedActivityForm.valid">check</mat-icon>
                    </span>
                </button>
            </ng-container>

            <ng-container *ngIf="currentSectionIndex !== sections.indexOf('expectedActivity')">
                <button class="text-white bg-blue-500 px-4 py-2 rounded w-100 pulse"
                    (click)="toggleReviewApplicationMode()"
                    *ngIf="!reviewApplicationMode && basicInfoForm.valid && residentialAddressForm.valid && verificationForm.valid && financialInfoForm.valid && expectedActivityForm.valid">
                    <!-- <mat-icon matListItemIcon class="white-icon">send</mat-icon> -->
                    <span>Review Your Application</span>
                </button>
            </ng-container>
        </mat-nav-list>
    </div>

    <!-- Column 2 -->
    <ng-container *ngIf="devices.isDesktop">
        <div class="flex-grow" style="flex-basis: 18.56%;">
            <!-- Column 2 content -->
        </div>
    </ng-container>
    <!-- Column 3 -->
    <div class="flex-grow w-full" style="flex-basis: 25.77%;">

        <ng-container
            *ngIf="(!reviewApplicationMode) && ((devices.isMobile) || (devices.isTablet) || (devices.isDesktop))">
            <!-- Welcome Back -->
            <ng-container
                *ngIf="((devices.isMobile) || (devices.isTablet)) && notFirstVisit && getInvalidForms().length > 0">
                <section class=" mt-40 mb-72">
                    <h3 class="text-blue-500 mb-4">{{getPageSection('welcome-back')?.title}}</h3>
                    <p class="mat-mdc-card-subtitle mb-20 text-white">
                        {{getPageSection('welcome-back')?.subtitle}}
                    </p>
                    <mat-nav-list *ngIf="getFirstInvalidForm()">
                        <button mat-list-item *ngIf="basicInfoForm && basicInfoForm.invalid"
                            (click)="scrollToSection(getFirstInvalidForm()?.code || '')"
                            class="section-select-button text-left mb-10 d-flex align-items-center justify-content-between shadow-md bg-white">
                            <mat-icon matListItemIcon class="red-icon">person</mat-icon>
                            <span class="text-gray-600 flex-grow-1 text-center">{{getFirstInvalidForm()?.title}}</span>
                            <span matListItemMeta class="red-icon"><mat-icon>chevron_right</mat-icon></span>
                        </button>
                    </mat-nav-list>
                </section>
            </ng-container>

            <!-- Basic Information -->
            <ng-container
                *ngIf="(devices.isMobile) || (devices.isTablet) || ((devices.isDesktop) && userAuthDetails && userAuthDetails?.user && sections[currentSectionIndex] === 'basicInfo')">
                <section id="basicInfoSection" class="mb-6 section-bottom-margin" #basicInfo>

                    <h4 class="text-blue-500 mb-4">{{getPageSection('basic-information')?.title}}</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">
                        {{getPageSection('basic-information')?.subtitle}}
                    </p>
                    <form [formGroup]="basicInfoForm" class="w-full">
                        <mat-form-field class="w-full" appearance="outline">
                            <input matInput [placeholder]="userAuthDetails?.user?.email" readonly disabled>
                        </mat-form-field>
                        <div class="flex space-x-4">
                            <mat-form-field class="w-full sm:w-1/2" appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput placeholder="First Name" formControlName="firstName">
                            </mat-form-field>
                            <mat-form-field class="w-full sm:w-1/2" appearance="outline">
                                <mat-label>Family Name</mat-label>
                                <input matInput placeholder="Family Name" formControlName="familyName">
                            </mat-form-field>
                        </div>
                        <mat-form-field class="w-full mb-4" appearance="outline">
                            <mat-label>Date of Birth</mat-label>
                            <input matInput [matDatepicker]="picker" placeholder="Date of Birth" formControlName="dob"
                                [max]="minDate">
                            <mat-hint align="start" class="text-gray-400 font-light">MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker" disabled></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="w-full mb-4" appearance="outline"><mat-label>Telephone</mat-label>
                            <span matTextPrefix>+1 &nbsp;</span>

                            <input matInput placeholder="Phone" formControlName="phone">
                            <mat-hint align="start" class="text-gray-400 font-light">+1 222 555-666</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="w-full mb-4" appearance="outline">
                            <mat-label>Social Security Number</mat-label>
                            <input matInput placeholder="Social Security Number" formControlName="socialSecurityNumber">
                            <mat-hint align="start" class="text-gray-400 font-light">123-45-6789</mat-hint>
                        </mat-form-field>
                        <div class="flex " style="gap: 1rem;">
                            <button (click)="goToNextSection('residential-address')"
                                class="text-white bg-blue-500 px-4 py-2 rounded">
                                Next
                            </button>
                        </div>
                    </form>

                </section>
            </ng-container>



            <ng-container
                *ngIf="(devices.isMobile) || (devices.isTablet) || ((devices.isDesktop) && sections[currentSectionIndex] === 'residentialAddress')">

                <!-- Residential Address -->
                <section class="mb-6 section-bottom-margin" #residentialAddress>
                    <h4 class="text-blue-500 mb-4">{{getPageSection('residential-address')?.title}}</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">
                        {{getPageSection('residential-address')?.subtitle}}
                    </p>
                    <form [formGroup]="residentialAddressForm" class="w-full">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Street</mat-label>
                            <input matInput placeholder="Street" formControlName="street">
                        </mat-form-field>
                        <mat-form-field class="w-full" appearance="outline"> <mat-label>City</mat-label>

                            <input matInput placeholder="City" formControlName="city">
                        </mat-form-field>
                        <div class="flex space-x-4">
                            <mat-form-field class="w-1/2" appearance="outline">
                                <mat-label>Zip Code</mat-label>
                                <input matInput placeholder="Zip" formControlName="zip">
                            </mat-form-field>

                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select placeholder="State" formControlName="state" appearance="outline">
                                    <mat-option *ngFor="let state of usStates" [value]="state.code">
                                        {{state.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select placeholder="Country" formControlName="country">
                                <mat-option *ngFor="let country of countries" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <app-file-drop class="my-4" proofCode="proof-of-address"
                            [existingImage]="residentialAddressForm.value.proofOfAddress || ''"
                            (base64Upload)="handleProofUpload($event)"></app-file-drop>


                        <div class="flex " style="gap: 1rem;">
                            <button (click)="goToPreviousSection()" *ngIf="devices.isDesktop"
                                class="text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded">
                                Back
                            </button>
                            <button (click)="goToNextSection('identification')"
                                class="text-white bg-blue-500 px-4 py-2 rounded">
                                Next
                            </button>
                        </div>
                    </form>
                </section>
            </ng-container>


            <ng-container
                *ngIf="(devices.isMobile) || (devices.isTablet) || ((devices.isDesktop) && sections[currentSectionIndex] === 'identification')">

                <!-- Identification -->
                <section class="mb-6 w-full section-bottom-margin" #verification>
                    <h4 class="text-blue-500 mb-4">{{getPageSection('identification')?.title}}</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">
                        {{getPageSection('identification')?.subtitle}}
                    </p>
                    <form [formGroup]="verificationForm" class="w-full">

                        <mat-form-field class="w-full full-width-mat-field" appearance="outline">
                            <mat-label>ID Type</mat-label>
                            <mat-select placeholder="ID Type" formControlName="idType">
                                <mat-option *ngFor="let idType of allowedProofOfIdentification" [value]="idType.code">
                                    {{idType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="w-full full-width-mat-field" appearance="outline"
                            *ngIf="showIdNumberOrNot(verificationForm.get('idType')?.value)">
                            <mat-label>ID Number</mat-label>
                            <input matInput placeholder="ID Number" formControlName="idNumber">
                        </mat-form-field>

                        <ng-container *ngIf="showExpiryDateOrNot(verificationForm.get('idType')?.value)">
                            <mat-form-field class="w-full mb-4" appearance="outline">
                                <mat-label>Expiration Date</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="Expiration Date"
                                    formControlName="idExpiry">
                                <mat-hint align="start" class="text-gray-400 font-light">MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker" disabled></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>


                        <mat-form-field class="w-full full-width-mat-field" appearance="outline">
                            <mat-label>Issuing Country</mat-label>
                            <mat-select placeholder="Issuing Country" formControlName="idCountry">
                                <mat-option *ngFor="let country of countries" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-file-drop class="my-4" proofCode="proof-of-identity"
                            [existingImage]="verificationForm.value.proofOfId || ''"
                            (base64Upload)="handleProofUpload($event)"></app-file-drop>
                        <div class="flex " style="gap: 1rem;">
                            <button (click)="goToPreviousSection()"
                                class="text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded"
                                *ngIf="devices.isDesktop">
                                Back
                            </button>
                            <button (click)="goToNextSection('banking-details')"
                                class="text-white bg-blue-500 px-4 py-2 rounded">
                                Next
                            </button>
                        </div>
                    </form>
                </section>
            </ng-container>

            <ng-container
                *ngIf="(devices.isMobile) || (devices.isTablet) || ((devices.isDesktop) && sections[currentSectionIndex] === 'financialInformation')">

                <!-- Banking Details -->
                <section id="financialSection" class="mb-6 section-bottom-margin" #financialInfo>
                    <h4 class="text-blue-500 mb-4">{{getPageSection('banking-details')?.title}}</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">
                        {{getPageSection('banking-details')?.subtitle}}
                    </p>
                    <form [formGroup]="financialInfoForm" class="w-full">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Bank Name</mat-label>

                            <input matInput placeholder="Bank Name" formControlName="bankName">
                        </mat-form-field>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Account Number</mat-label>

                            <input matInput placeholder="Account Number" formControlName="accountNumber">
                        </mat-form-field>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Routing Number</mat-label>

                            <input matInput placeholder="Routing Number" formControlName="routingNumber">
                        </mat-form-field>
                        <app-file-drop class="my-4" proofCode="proof-of-bank"
                            [existingImage]="financialInfoForm.value.proofOfBank || ''"
                            (base64Upload)="handleProofUpload($event)"></app-file-drop>

                        <div class="flex " style="gap: 1rem;">
                            <button (click)="goToPreviousSection()"
                                class="text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded"
                                *ngIf="devices.isDesktop">
                                Back
                            </button>
                            <button (click)="goToNextSection('additional-information')"
                                class="text-white bg-blue-500 px-4 py-2 rounded">
                                Next
                            </button>
                        </div>
                    </form>
                </section>
            </ng-container>

            <ng-container
                *ngIf="(devices.isMobile) || (devices.isTablet) || ((devices.isDesktop) && sections[currentSectionIndex] === 'expectedActivity')">

                <!-- Additional Information -->
                <section id="expectedActivitySection" class="mb-6 section-bottom-margin" #expectedActivity>
                    <h4 class="text-blue-500 mb-4">{{getPageSection('additional-information')?.title}}</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">
                        {{getPageSection('additional-information')?.subtitle}}</p>
                    <form [formGroup]="expectedActivityForm" class="w-full">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-select placeholder="Estimated Monthly Revenue"
                                formControlName="estimatedMonthlyRevenue">
                                <mat-option *ngFor="let revenue of estimatedMonthlyRevenue" [value]="revenue.code">
                                    {{revenue.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="w-full" appearance="outline">
                            <textarea matInput placeholder="Describe intended use of account"
                                formControlName="intendedUseOfAccount" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                        <div class="flex " style="gap: 1rem;">
                            <button (click)="goToPreviousSection()"
                                class="text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded"
                                *ngIf="devices.isDesktop">
                                Back
                            </button>
                            <button (click)="toggleReviewApplicationMode()"
                                class="text-white bg-blue-500 px-4 py-2 rounded pulse"
                                [class.opacity-50]="!basicInfoForm.valid && !residentialAddressForm.valid && !verificationForm.valid && !financialInfoForm.valid && !expectedActivityForm.valid"
                                [disabled]="!basicInfoForm.valid && !residentialAddressForm.valid && !verificationForm.valid && !financialInfoForm.valid && !expectedActivityForm.valid"
                                *ngIf="devices.isDesktop">
                                Review Your Application
                            </button>
                        </div>
                    </form>
                    <ng-container *ngIf="(devices.isMobile) || (devices.isTablet)">
                        <button color="primary" class="text-white bg-blue-500 px-4 py-2 rounded w-100 mt-5 pulse"
                            (click)="toggleReviewApplicationMode()"
                            [class.opacity-50]="!basicInfoForm.valid && !residentialAddressForm.valid && !verificationForm.valid && !financialInfoForm.valid && !expectedActivityForm.valid"
                            [disabled]="!basicInfoForm.valid && !residentialAddressForm.valid && !verificationForm.valid && !financialInfoForm.valid && !expectedActivityForm.valid">
                            <span>Review Your Application</span>
                        </button>
                    </ng-container>
                </section>
                <section
                    *ngIf="((devices.isMobile) || (devices.isTablet)) && !reviewApplicationMode && getInvalidForms().length > 0">
                    <h4 class="text-blue-500 mb-6">Almost Done</h4>
                    <p class="mat-mdc-card-subtitle text-gray-500 mb-20">Please go back and check the following
                        sections
                    </p>
                    <mat-nav-list>
                        <button mat-list-item *ngFor="let invalidSection of getInvalidForms()"
                            (click)="scrollToSection(invalidSection?.code)"
                            class="section-select-button text-left mb-10 d-flex align-items-center justify-content-between shadow-md">
                            <mat-icon matListItemIcon class="red-icon">{{invalidSection?.matIcon}}</mat-icon>
                            <span class="text-gray-600 flex-grow-1 text-center">{{invalidSection?.title}}</span>
                            <span matListItemMeta><mat-icon class="red-icon">chevron_right</mat-icon></span>
                        </button>
                    </mat-nav-list>
                </section>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="reviewApplicationMode">
            <section class="mb-6 section-bottom-margin w-full">
                <h4 class="text-blue-500 mb-4">Review Your Application</h4>
                <p class="mat-mdc-card-subtitle text-gray-500 mb-20">Please review your application before
                    submitting
                </p>

                <mat-card class="review-application w-full mb-4">
                    <ng-container *ngFor="let form of formsPresentationData | keyvalue">
                        <mat-card-header>
                            <h4 class="text-blue-500 mb-4">{{form.value.formTitle}}</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="flex" *ngFor="let field of form.value.fields">
                                <div class="flex-1">
                                    <p class="mat-mdc-card-subtitle text-gray-500">{{field.title}}</p>
                                </div>
                                <div class="flex-1">
                                    <p *ngIf="field.inputType != 'image'" class="mat-mdc-card-subtitle text-gray-500">{{
                                        getPrettyFormValue(form.value.formTitle,field.title,field.value)}}</p>

                                    <img *ngIf="field.inputType === 'image'"
                                        [src]="field.value.includes('pdf') ? 'assets/pdf-icon.png' : field.value"
                                        [style.height.px]="field.value.includes('pdf') ? 50 : 100" alt="">
                                </div>
                            </div>
                        </mat-card-content>
                    </ng-container>
                </mat-card>
                <mat-checkbox class="mb-4" [(ngModel)]="termsAccepted">
                    I hereby acknowledge and agree to the terms and conditions of <a href="" target="_blank"
                        rel="noopener noreferrer" class="text-blue-500">Naybrs, Inc</a> and <a
                        href="https://stripe.com/legal/connect-account" target="_blank" rel="noopener noreferrer"
                        class="text-blue-500"> Stripe Connect</a>.
                </mat-checkbox>

                <ng-container
                    *ngIf="basicInfoForm.valid && residentialAddressForm.valid && verificationForm.valid && financialInfoForm.valid && expectedActivityForm.valid">

                    <div class="flex " style="gap: 1rem;">
                        <button class=" text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded w-50"
                            (click)="toggleReviewApplicationMode()">
                            <span>Go Back</span>
                        </button>
                        <button (click)="submitAccountForReview()"
                            class="text-white bg-blue-500 px-4 py-2 rounded submit-button w-50 flex flex-row items-center justify-center space-x-2"
                            [disabled]="!termsAccepted" [class.bg-gray-500]="!termsAccepted">
                            <mat-icon matListItemIcon class="white-icon">send</mat-icon>
                            <span>Submit</span>
                        </button>
                    </div>
                    <p class="text-red-500" *ngIf="submitApplicationError">{{submitApplicationError}}</p>
                </ng-container>

            </section>
        </ng-container>

    </div>

    <!-- Column 4 -->
    <ng-container *ngIf="devices.isDesktop">
        <div class="flex-grow" style="flex-basis: 18.56%;">
            <!-- Column 2 content -->
        </div>
    </ng-container>

    <!-- Column 5 -->
    <ng-container *ngIf="devices.isDesktop">
        <div class="flex-grow" style="flex-basis: 18.56%;">
            <!-- Column 2 content -->
        </div>
    </ng-container>