import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators'
import { differenceInDays, differenceInSeconds } from 'date-fns'
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { EditStore, StoreAdmin } from '../../models/store.model';
import { environment } from '../../../environments/environment'
import { Order } from '../../models/order.model';
import { AccountApplication, BankDetails, ContactInformation, GovernmentIssuedID, ProofOfAddress, ResidentialAddress } from '../../models/application.model';


@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    public platform: Platform,
    private http: HttpClient
  ) { this.platform = platform; }

  carryOverOrders: any;

  // globally available selected order to scan
  selOrder: Order | {} = {};
  private SelectedOrder = new BehaviorSubject(this.selOrder);
  selectedOrder = this.SelectedOrder.asObservable();

  changeSelectedOrder(changeTo: any) {
    console.log('changeSelectedOrder function', changeTo)
    this.SelectedOrder.next(changeTo);
  }

  userStore: StoreAdmin | {} = {};
  private Store = new BehaviorSubject(this.userStore);
  store = this.Store.asObservable();

  updateUserStore(changeTo: any) {
    console.log('updatedUserStore function')
    this.Store.next(changeTo);
  }

  orders: Array<any> = [];
  loadedOrders: Array<any> = []

  categoryOrder = ["Naybrs/Groceries/Meat & Seafood/Beef",
    "Naybrs/Groceries/Meat & Seafood/Chicken",
    "Naybrs/Groceries/Meat & Seafood/Lamb",
    "Naybrs/Groceries/Meat & Seafood/Pork",
    "Naybrs/Groceries/Produce/Fruit",
    "Naybrs/Groceries/Produce/Herbs",
    "Naybrs/Groceries/Produce/Vegetables",
    "Naybrs/Groceries/Condiments & Sauces/Pasta Sauce",
    "Naybrs/Groceries/Condiments & Sauces/Sauce",
    "Naybrs/Groceries/Dairy/Cheese",
    "Naybrs/Personal Care/Body/Deodorant",
    "Naybrs/Personal Care/Body/Moisurizer",
    "Naybrs/Personal Care/Body/Wash",
    "Naybrs/Personal Care/Face/Cleanser",
    "Naybrs/Personal Care/Face/Wash",
    "Naybrs/Personal Care/Hair/Conditioner",
    "Naybrs/Personal Care/Hair/Shampoo",
    "Naybrs/Personal Care/Oral Care/Toothpaste",
    "Naybrs/Personal Care/Supplements/Daily",
    "Naybrs/Household/Bathroom/Facial Tissue",
    "Naybrs/Household/Bathroom/Toilet Paper",
    "Naybrs/Household/Kitchen/Dish Wash",
    "Naybrs/Household/Kitchen/Dishwasher Pods",
    "Naybrs/Household/Kitchen/Paper Towel",
    "Naybrs/Household/Laundry/Detergent",
    "Naybrs/Household/Laundry/Fabric Softener",
    "Naybrs/Household/Laundry/Powder Detergent",
    "Naybrs/Household/Laundry/Sheets",
    "Naybrs/Pets/Cat/Dry Food",
    "Naybrs/Pets/Cat/Wet Food",
    "Naybrs/Pets/Dog/Dry Food",
    "Naybrs/Pets/Dog/Treats",
    "Naybrs/Pets/Dog/Wet Food"]

  // ------------------------------------------

  // globally available list of orders
  ordersLi: any = [];
  private OrdersList: BehaviorSubject<Order[] | []> = new BehaviorSubject(this.ordersLi);
  ordersList: Observable<Order[] | []> = this.OrdersList.asObservable();
  setOrdersList(changeTo: any) {
    this.OrdersList.next(changeTo);
  }
  // ------------------------------------------
  //  APPLICATION

  saveApplicationFields(saveFields: AccountApplication): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/secure/account`
    const headers = { 'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem('authData') as string).jwt }
    const body = { ...saveFields };
    return this.http.patch<boolean>(url, body, { headers })
  }



  // ---------------------------------------
  getOrders(jwt: string) {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/orders/`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    return this.http.get(url, { headers })
  }


  changeOrderStatus(entityId: number, status: string, jwt: string) {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/orders/change-order-status`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    const body = JSON.stringify({
      entity: { entity_id: entityId, state: "processing", status: status },
    });
    return this.http.post(url, body, { headers })
  }

  createInvoiceAndComplete(entityId: number, jwt: string): Observable<any> {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/invoices/customer`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    const body = { entity_id: entityId };
    return this.http.post(url, body, { headers })
  }

  notifyCustomerOrderReady(entityId: number, jwt: string) {
    const url = `${environment.naybrsAwsApi}//store/owner/secure/orders/ready-for-pickup`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    const body = { orderIds: [entityId] };
    return this.http.post(url, body, { headers })
  }

  notifyCustomerOrderReadyPush(order: Order, jwt: string) {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/notifications/send`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    const body = { store_name: order.shipping_description.replace('Pick-Up - ', '').replace('Delivery - ', ''), telephone: order.billing_address.telephone, notificationCode: "ready-for-collection", increment_id: order.increment_id, entity_id: order.entity_id, storeId: order.store_id, storeCode: order.extension_attributes.pickup_location_code, customerFirstName: order.customer_firstname, customerLastName: order.customer_lastname };
    console.log('notifyCustomerOrderReadyPush body', body)
    return this.http.post(url, body, { headers })
  }

  getStore(source_code: string, jwt: string): Observable<StoreAdmin> {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/store/profile`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    return this.http.get<StoreAdmin>(url, { headers })
  }

  getStoreWithFilteredBody(storeCode: string, jwt: string) {
    return this.getStore(storeCode, jwt).pipe(
      map((resp: any) => {
        // Transform resp.body into an array of key-value pairs

        if (resp.body === null || resp.body === undefined) { return {} }

        const entries = Object.entries(resp.body);

        // Filter out the pairs where the value is an empty object
        const filteredEntries = entries.filter(([key, value]) => !(typeof value === 'object' && value !== null && Object.keys(value).length === 0));

        // Transform the array back into an object
        resp.body = Object.fromEntries(filteredEntries);

        return resp;
      })
    );
  }




  editStore(params: EditStore, jwt: string): Observable<any> {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/store/profile`
    const headers = { 'Content-Type': 'application/json', 'Authorization': jwt }
    return this.http.patch(url, params, { headers })
  }
}
