@if (!isLoading) {
<div>
  <div class="home-header ww-border-b flex-wrap hide-gt-sm">
    <mat-toolbar> <a [routerLink]="['/']" routerLinkActive="router-link-active">
        <naybrs-logo [width]="'120'"> </naybrs-logo>
      </a>
      <span class="example-spacer"></span>
      @if (currentRoute === '/auth/login' && !isLoggedIn) {
      <button mat-stroked-button color="primary" routerLink="/auth/signup">Open Store</button>
      }
      @if (currentRoute === '/auth/signup' && !isLoggedIn) {
      <button mat-stroked-button color="primary" routerLink="/auth/login">Log In</button>
      }
      @if (isLoggedIn) {
      <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="userMenu">
        {{ user?.user?.first_name?.charAt(0) || '' }}{{ user?.user?.family_name?.charAt(0) || '' }}
        <!-- <mat-icon>account_circle</mat-icon> -->
      </button>
      }
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Log Out</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  </div>
  <div class="home-header ww-border-b hide-xs hide-sm">
    <div class="flex-row flex-col-sm justify-space-between items-center">
      <div class="flex-row flex-row-sm justify-center items-center">
        <mat-toolbar> <a [routerLink]="['/']" routerLinkActive="router-link-active">
            <naybrs-logo [width]="'120'"> </naybrs-logo>
          </a>
        </mat-toolbar>
      </div>
      <div class="call-bucket-wrap flex-row flex-row-sm  justify-center items-center hide-xs hide-sm">
        <mat-toolbar class="top-navbar top menu">
          @if (currentRoute === '/auth/signup' && !isLoggedIn) {
          <button mat-stroked-button color="primary" routerLink="/auth/login">Log In</button>
          }
          @if (isLoggedIn) {
          <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="userMenu">
            {{ user?.user?.first_name?.charAt(0) || '' }}{{ user?.user?.family_name?.charAt(0) || '' }}
          </button>
          }
          @else {
          @if (currentRoute != '/auth/signup') {

          <button mat-stroked-button color="primary" routerLink="/auth/signup">Open Store</button>
          }
          }
          <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>Log Out</span>
            </button>
          </mat-menu>
        </mat-toolbar>
      </div>
    </div>
  </div>
</div>
}