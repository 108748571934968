import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AuthService } from './shared/services/auth.service';
import { AuthUser } from './auth/user.model';
import { UserAccount } from './models/user.model';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  isLoggedIn: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.autoLogin();
    let authUser: AuthUser | null;

    const authData = localStorage.getItem('authData');
    if (authData) {
      authUser = JSON.parse(authData);
      // Check if jwt is not expired
      if (authUser && new Date(authUser.jwt_expires_at) > new Date()) {
        // this.authService.updateUser(user);
        this.isLoggedIn = true;
      } else {
        console.log('JWT has expired. ');
        this.authService.logout();
      }
    }

    if (this.isLoggedIn) {
      this.authService.getUserAccount().subscribe((resp: UserAccount) => {
        console.log('resp', resp);
        this.authService.setUserData(Object.keys(resp).length === 0 ? null : ({ ...authUser, user: resp } as AuthUser));
      });
    }
  }
}
