@if (devices.isDesktop) {
<mat-card class="center-file-drop  mb-15">
  <mat-card-header class="mb-4">
    <mat-card-title class="text-blue-500">{{ getDocument(proofCode)?.title }}</mat-card-title>
    <mat-card-subtitle class="text-gray-500 text-sm font-light">{{ getDocument(proofCode)?.description
      }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)" [dropZoneClassName]="'drop-zone'" [multiple]="false"
      [accept]="'image/png,image/gif,image/jpg,image/jpeg,image/webp,application/pdf'">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="file-zone-drop flex items-center justify-center w-full" (click)="openFileSelector()">
          <label for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-transparent dark:hover:bg-transparent dark:bg-transparent hover:bg-transparent dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                </path>
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to
                  upload</span> or drag and drop</p>
              <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, WEBP, GIF or PDF (MAX.
                50mb)</p>
            </div>
          </label>
        </div>
      </ng-template>
    </ngx-file-drop>
    <p class="text-red-500 mt-4" [style.opacity]="userWarning.visible ? '1' : '0'"
      [style.visibility]="userWarning.visible ? 'visible' : 'hidden'">{{userWarning.message}}</p>
  </mat-card-content>
  <mat-card-actions class="flex flex-col">
    @if (files.length > 0) {
    <div class="upload-table">
      @for (file of files; track file) {
      <div class="flex items-center space-x-4">
        <!-- Image thumbnailasda -->
        <div class="w-24 h-24 overflow-hidden">
          <img [src]="file?.relativePath?.includes('.pdf') ? 'assets/pdf-icon.png' : file?.base64String"
            alt="Description" class="h-full w-auto object-cover object-center">
        </div>
        <!-- File namasdae -->
        <div class="flex-grow">
          <p class="text-center">{{ file.relativePath || (file ? 'Uploaded file' : '') }}</p>
        </div>
        <!-- Delete button -->
        <button (click)="clearUploadedFile()" class="flex-shrink-0 pr-4">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      }
    </div>
    }
  </mat-card-actions>
</mat-card>
}
@if ((devices.isMobile) || (devices.isTablet)) {
<div class="mb-15">
  <input type="file" (change)="onNativeFileSelected($event, 'identification')" style="display: none;" #fileInput>
  <button (click)="fileInput.click()"
    class="text-blue-500 bg-white border border-blue-500 px-4 py-2 rounded flex items-center justify-center font-medium">
    Upload Proof <mat-icon class="text-blue-500 ml-2">upload</mat-icon>
  </button>
</div>
<div class="flex flex-col mb-15">
  @if (mobileFile) {
  <div class="upload-table">
    <div class="flex items-center space-x-4">
      <!-- Image thumbnail -->
      <div class="w-24 h-24 overflow-hidden">
        <p>{{mobileFile | json}}</p>
        <img class="mobile-icon" [src]="mobileFile.includes('.pdf') ? 'assets/pdf-icon.png' : mobileFile"
          alt="Description" class="h-full w-auto object-cover object-center">
      </div>
      <!-- File name -->
      <div class="flex-grow">
        <p class="text-center">{{ mobileFileName ? mobileFileName : (mobileFile ? 'Uploaded file' : '') }}
        </p>
      </div>
      <!-- Delete button -->
      <button (click)="clearMobileUploadedFile()" class="flex-shrink-0 pr-4">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  }
</div>
}